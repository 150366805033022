import {
	ChangeDetectorRef,
	Component,
	DoCheck,
	ElementRef,
	HostListener,
	Injector,
	OnDestroy,
	OnInit,
	ViewChild
} from "@angular/core";
import { Router } from "@angular/router";
import { StreamViewModel } from "../service/viev-model/stream-view-model";
import { NotifyPanel } from "../../../notification-panel/notification-panel.component";
import { SELF_VIDEO } from "../data/local-constants";
import { createCustomElement } from "@angular/elements";
import { UserComponent } from "../../user-call/user.component";
import { PopupService } from "../../../services/popup/popup.service";
import { ChatService } from "../../chat/services/chat.service";
import { PlatformDetectorService } from "../../../services/platform-detector/platform-detector.service";
import { LoggingService } from "../../../services/logging/logging.service";
import {
	DEMAND,
	JOIN,
	WebSocketService
} from "../../../services/ws/web-socket.service";
import {
	EventSocketService,
	SOCKET_EVENT
} from "../../../services/ws/event-socket.service";
import { CallRoom, Room } from "./call-room";
import { CallStorage } from "../service/storage/call-storage.service";
import { DisplayStatus } from "../service/storage/DisplayStatusVO";
import { TranslateService } from "@ngx-translate/core";
import {
	of,
	Subject,
	Subscription,
	throwError,
	fromEvent,
	Observable
} from "rxjs";
import { RoomStateStorage } from "../service/storage/RoomStateStorage";
import { USER_KEY } from "../../../constants/localstorage-constants";
import {
	catchError,
	mergeMap,
	takeUntil,
	debounceTime,
	exhaustMap
} from "rxjs/operators";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { CustomThemeService } from "../../../services/custom-theme.service";
import { UserVO } from "../../../services/data/UserVO";
import { UsersService } from "../../../services/users.service";
import { RoomDataParserService } from "../../../services/ws/room-data-parser.service";
import { StreamSettingsStorage } from "../service/storage/rtc-storage.service";
import { VideoService } from "../../../video/video.service";
import { JoinRequest } from "../../../models/join-request";
import { RoomAuthService } from "../../../services/ws/room-auth.service";
import { MediaSettingsStorageService } from "../service/storage/media-settings-storage.service";
import { StorageUtil } from "src/app/utils/storage.util";
import { SoundPlayerUtil } from "src/app/lib-sound-player/sound-player.util";
import { logger } from "src/app/lib-core/logger";
import { SoundPlayer } from "src/app/lib-sound-player/sound-player";
import {
	MEDIA_AUDIO_OUTPUT_DEVICE,
	MEDIA_VIDEO_IS_REFLECTION
} from "src/app/lib-core/constants/constants";
import { LocalStorageUtil } from "src/app/lib-core/utils/local-storage.util";
import {
	MediaProperies,
	MediaProperiesUtil
} from "src/app/lib-setting/components/setting-popup/setting-popup.interface";
import { RtcStreamViewModel } from "../service/viev-model/rtc-stream-view-model";
import { MediaDevicesService } from "src/app/lib-media-devices/services/media-devices.service";
import { EasyRtcService } from "src/app/lib-rtc/services/easy-rtc.service";
import { FormatTimeUtil } from "src/app/lib-core/utils/format-time.util";
import { Tariff, TariffUtil } from "src/app/types/tariff-constants";
import { Profile } from "../../../types/profile.types";
import {
	DEBUG_VALUE_24,
	LocationUtil,
	PRM_DEBUG
} from "src/app/lib-core/utils/location.util";
import { TeacherRequest } from "src/app/models/teacher-request";
import {
	getSubDomain,
	placeNameOnVideos,
	getTeacherName,
	removeChildById,
	getMainVideo,
	getSelfVideo,
	getVideoById
} from "src/app/helpers";
import { QualityRatingStorageUtil } from "src/app/lm-quality-rating/utils/quality-rating-storage.util";
import { SessionStorageUtil } from "src/app/lib-core/utils/session-storage.util";
import { ProfileService } from "src/app/profile/profile.service";
import { LanguageService } from "src/app/services/languages/language.service";
import { PlatformService } from "src/app/platform/platform.service";
import { IconService } from "@shared/services/icon.service";
import { CallRoomService } from "../service/call-room-service";
import watchRTC from "@testrtc/watchrtc-sdk";
import { environment } from "@env/environment";
import { EmojiService } from "src/app/emoji/emoji.service";
import { SpeakerTimeDetectorService } from "../../../services/speaker-time-detector.service";
import { BotService } from "../../../services/bot/bot.service";
import { ensureGlobalObject } from "../../../services/utils";
import {
	RecordingListItem,
	RecordingService
} from "../../../services/recording/recording.service";
import { ButtonType } from "../data/ButtonType";
import { BG_THEMES, DEFAULT_BG_COLOR } from "../data/themes";
import { PlayerStateService } from "../../../services/youtube/player-state.service";
import { MirroringService } from "../service/mirroring.service";
import { NotesService } from "src/app/services/notes/notes.service";
import { LessonsService } from "src/app/services/lessons/lessons.service";
import dayjs from "dayjs";
// import { AccessControlService } from "src/app/services/access-control/access-control.service";
// import { Resources } from "src/app/services/access-control/resources.constants";
import { SubRole } from "src/app/constants/subroles-constants";
import { MixpanelService } from "src/app/services/mixpanel/mixpanel.service";
import { RoomService, RoomType } from "src/app/services/room.service";
import { AudioContextService } from "src/app/services/audio-context/audio-context.service";
import { TalkServiceService } from "src/app/talkjs-chat/services/talk-service.service";
import { IntercomService } from "src/app/services/intercom/intercom.service";
import { CallSiriusCloudService } from "../../call-sirius-cloud/call-sirius-cloud.service";
import { QualityRatingService } from "src/app/lm-quality-rating/services/quality-rating.service";

export const OFF = "off";

@Component({
	template: ""
})
export class CallRoomComponent implements CallRoom, OnInit, OnDestroy, DoCheck {
	roomName: string = "";
	destroyed: Subject<void> = new Subject<void>();
	private storeSpeakersTimeSubject = new Subject<{
		userType: string;
		time: number;
	}>();
	// audioInterrupt: HTMLAudioElement = new Audio('/assets/BellBouncer.mp3');

	public isHiddenSetting = false;
	public isHiddenInterrupt = false;
	public isHiddenEmoji = false;
	public isHiddenCloud = false;
	public isEmoji = false;
	public isCloud = false;
	public isHandOn = false;
	public isHiddenMetronome = false;
	public isMetronome = false;
	public isHiddenTuner = false;
	public isTuner = false;
	public isHiddenScreenShare = false;
	public isScreenShare = false;
	public isHiddenSplitView = false;
	public isSplitViewEnabled = true;
	public isSplitView = true;
	public isHiddenChat = false;
	public isHiddenInvitation = false;
	public isHiddenLeaveButton = false;
	public isBotOrProTeacher = false;
	public isPiano = false;
	public isFullscreen = false;
	public showTimer = true;
	public isHiddenNotes = true;
	public bgColor = DEFAULT_BG_COLOR;
	private isNotSubDomainAndFree = false;
	public getTeacherName = getTeacherName;
	public maximizeSecondCam = false;

	userName: string;
	displayChat: boolean = false;
	displayCloud: boolean = false;
	displayMaterial: boolean = false;
	displayNotes: boolean = false;
	displayInvitation: boolean = false;
	hasUnreadMessages: boolean = false;
	roomLink: string;
	roomPassword: string;
	// Device detection
	isDesktop: boolean = true;
	isMobile: boolean = false;
	isTablet: boolean = false;
	isTabletOrMobileSafari = false;
	isPortrait: boolean = false;
	isLandscape: boolean = false;
	// deviceInfo: DeviceInfo;
	isBrowserSafari: boolean = false;
	isBrowserFirefox = false;
	isLoading: boolean = false;
	streamViewModel: StreamViewModel;
	private subscribesLocal: Array<Subscription> = new Array<Subscription>();
	redirectRoute: string = "";
	oldRisehandStatuses: boolean[] = [];
	private copyTimeout: ReturnType<typeof setTimeout>;
	public copySuccess = false;
	public selfieVideoDisplayed: boolean;
	usersCount: number = 0;
	outputDeviceId: string;
	public isSign = true;
	public isProcessing = false;
	public isOldVariant = false;
	public isReflection = false;
	public joinList: JoinRequest[] = [];
	wasLoggedIn = false;
	public periodWarning = "";
	public isShowReleaseWarning = false;
	public teacherRequestList: TeacherRequest[] = [];
	private audioContext: AudioContext;
	private soundPlayerHandOn: SoundPlayer;
	protected isDestroy = false;
	private timeReleaseWarning: number | null = null;
	private releaseWarningTimeout;
	public isDisplayStatusActive = false;
	private isTeacherRequestUnmute = false;
	private isTeacherRequestUnhide = false;
	private isDebug = false;
	public notJoinedList: NotifyPanel[] = [];
	public bandwidth: number = null;
	public lowBandwidth = false;
	public bitrateString: string;
	private tariff: Tariff;
	public updatePanelSplit = true;
	public inbound: object;
	public packetsLostLive: number = null;
	public currentVideoWidth: string;
	private prevVideoWidth: number;
	public isRecordingTurnOn = false;
	private profileId: string;
	public isSettingsDialogOpen = false;
	private mobilePortraitViewIsSplit = true;
	public switchCam = false;
	public toggleShare = false;
	public shouldNotMirrored = false;
	public latencyTurnCamera = false;
	public isSchool = false;
	public isYouTube = false;
	public inputDeviceChange = false;
	public outputDeviceChange = false;
	public currentInputDevice: string;
	public currentOutputDevice: string;
	public currentInputs;
	public currentOutputs;
	public isCallEnded = false;
	public showNextLessonAlert = false;
	public subrole;
	public betaTesting;
	public showRecordingError = false;
	private isScheduledLesson;
	public userId;
	private bookingType;
	private nextLesson;
	private nextLessonTimer;
	public currentStudent;
	public scheduledLessonRole;
	public isRoomIdCreated: boolean = false;
	public roomType: RoomType;
	public userProfile: Profile;
	public currentLesson;
	private studentTimer: number = 0;
	private teacherTimer: number = 0;
	tunerPosition = { x: 20, y: 100 };
	@ViewChild("tunerElement", { static: false }) tunerElement!: ElementRef;
	isDragging = false;
	offset = { x: 0, y: 0 };
	isTrial$: Observable<boolean>;

	get videoElementWidth(): string {
		const videoElement: any = document.querySelector(
			"div.main-panel.ng-star-inserted.is-cam-on > app-view-media > video"
		);
		if (videoElement) {
			const cssObj = window.getComputedStyle(videoElement, null);
			return cssObj.getPropertyValue("width");
		} else {
			return "0px";
		}
	}

	get getCurrentWidth(): string {
		if (!this.isSplitView) {
			if (
				(this.userOnPanel()?.isCamera &&
					this.isDesktop &&
					!this.isBrowserFirefox &&
					!this.isSplitView &&
					!this.isBrowserSafari) ||
				(this.isTablet && this.isLandscape && !this.isBrowserSafari)
			) {
				return "unset";
			} else if (
				this.userOnPanel()?.isCamera &&
				this.isDesktop &&
				(this.isBrowserFirefox || this.isBrowserSafari)
			) {
				// const callContainer: any = document.querySelector('#callContainer');
				// const cssObj = window.getComputedStyle(callContainer, null);
				// return cssObj.getPropertyValue('width');
				// return this.callContainerWidth
			} else {
				return "100%";
			}
		}
	}

	get screenSaverWidth(): string {
		const innerWidth =
			document.getElementById("video-component").clientWidth;

		if (innerWidth > 768 && !this.isTablet) {
			if (!this.prevVideoWidth || this.prevVideoWidth <= 768) {
				return "calc((100vh - 98px) * (4/3))";
			} else {
				return this.prevVideoWidth + "px";
			}
		} else {
			return "100vw";
		}
	}

	get marginClass(): boolean {
		if (
			this.userOnPanel().isCamera &&
			this.isDesktop &&
			!this.isBrowserFirefox &&
			!this.isSplitView &&
			!this.isBrowserSafari
		) {
			return true;
		} else return false;
	}

	get innerHeight(): number {
		return window.innerHeight;
	}

	get innerWidth(): number {
		return window.innerWidth;
	}

	get testRoom(): boolean {
		return ["room-watchrtc", "room-60"].includes(this.callStorage.roomId);
	}

	// todo add to callStorage
	constructor(
		private routerLocal: Router,
		protected changeDetector: ChangeDetectorRef,
		public callStorage: CallStorage,
		public roomState: RoomStateStorage,
		protected rtcStreamViewModel: RtcStreamViewModel,
		private injectorLocal: Injector,
		protected popupService: PopupService,
		protected chatService: ChatService,
		protected notesService: NotesService,
		protected loggingService: LoggingService,
		protected platformDetectorService: PlatformDetectorService,
		protected webSocketService: WebSocketService,
		protected eventSocketService: EventSocketService,
		protected translateService: TranslateService,
		protected authenticationService: AuthenticationService,
		public customThemeService: CustomThemeService,
		protected usersService: UsersService,
		protected roomDataParserService: RoomDataParserService,
		protected settingsStorage: StreamSettingsStorage,
		protected videoService: VideoService,
		protected roomAuthService: RoomAuthService,
		protected mediaDevicesService: MediaDevicesService,
		protected easyRtcService: EasyRtcService,
		protected mediaSettings: MediaSettingsStorageService,
		public profileService: ProfileService,
		public languageService: LanguageService,
		public platformService: PlatformService,
		public iconService: IconService,
		public callRoomService: CallRoomService,
		public emojiService: EmojiService,
		public speakerTimeDetectorService: SpeakerTimeDetectorService,
		public botService: BotService,
		public recordingService: RecordingService,
		public playerStateService: PlayerStateService,
		public mirrorService: MirroringService,
		public lessonService: LessonsService,
		// protected accessControlService: AccessControlService,
		protected mixpanelService: MixpanelService,
		public roomService: RoomService,
		protected audioContextService: AudioContextService,
		private talkjsService: TalkServiceService,
		public intercom: IntercomService,
		public callCloud: CallSiriusCloudService,
		public qualityRating: QualityRatingService
	) {
		this.bgColor = localStorage.getItem("bgColor") || "#162329";

		this.streamViewModel = rtcStreamViewModel;
		this.isMobile = this.platformDetectorService.isMobile();
		this.isTablet = this.platformDetectorService.isTablet();
		this.isDesktop = this.platformDetectorService.isDesktop();
		this.isBrowserFirefox = this.platformDetectorService.isFirefox();
		const deviceInfo = this.platformDetectorService.deviceInfo;
		this.isBrowserSafari = deviceInfo.browser === "Safari";
		this.isTabletOrMobileSafari =
			(this.isMobile ||
				this.isTablet ||
				this.platformDetectorService.isIPad()) &&
			this.isBrowserSafari;

		this.getOrientation();
		this.isDebug =
			LocationUtil.findGetParameter(PRM_DEBUG) === DEBUG_VALUE_24;
		if (this.isDebug) {
			alert(
				"init() window:orientationchange isPortrait=" + this.isPortrait
			);
		}
		this.authenticationService.updateInTheRoomStatus(true);
		// this.audioInterrupt.volume = 0.5;
		const profile = this.authenticationService.profileInfo();
		// SIRIUS-708 Audio & video quality rating
		// If the user is not logged in, he will see the quality assessment page.
		const isTariffFree = !!profile
			? profile.subscription.type === Tariff.FREE
			: true;
		this.isNotSubDomainAndFree = !getSubDomain() && isTariffFree;
		QualityRatingStorageUtil.cleanRatingParamsStorage();
		SessionStorageUtil.writeRatingRoomAliasToSessionStorage("");
		this.isHiddenSetting = LocationUtil.findGetParameter("setting") === OFF;
		this.isHiddenInterrupt =
			LocationUtil.findGetParameter("interrupt") === OFF;
		this.isHiddenEmoji = LocationUtil.findGetParameter("emoji") === OFF;
		this.isHiddenCloud = LocationUtil.findGetParameter("cloud") === OFF;
		this.isHiddenMetronome =
			LocationUtil.findGetParameter("metronome") === OFF;
		this.isHiddenTuner = LocationUtil.findGetParameter("tuner") === OFF;
		this.isHiddenScreenShare =
			LocationUtil.findGetParameter("screenshare") === OFF;
		this.isHiddenSplitView =
			LocationUtil.findGetParameter("participantView") === OFF;
		this.isHiddenChat = LocationUtil.findGetParameter("chat") === OFF;
		this.isHiddenInvitation =
			LocationUtil.findGetParameter("invite") === OFF;

		if (!this.authenticationService.accessToken && !BotService.isBot) {
			this.isHiddenInvitation = true;
		}
		this.isHiddenLeaveButton =
			LocationUtil.findGetParameter("leaveButton") === OFF;

		// this.subrole = localStorage.getItem('subrole');
		this.bookingType = sessionStorage.getItem("bookingType");
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		this.isScheduledLesson = !!urlParams.get("roomKey");
		this.scheduledLessonRole = urlParams.get("role");
		this.userId = urlParams.get("userId");

		this.setisHiddenNotes();
		this.intercom.hideIntercom(true);

		this.storeSpeakersTimeSubject
			.pipe(
				debounceTime(1000),
				exhaustMap(({ userType, time }) =>
					this.storeSpeakersTimeHandler(userType, time)
				)
			)
			.subscribe(
				() => {
					console.log("Speaker time stored successfully.");
				},
				(err) => {
					console.error("Error storing speaker time:", err);
				}
			);
		this.subscribeToSpeakerTime(
			this.speakerTimeDetectorService.speakerTimeData$,
			"student"
		);

		this.subscribeToSpeakerTime(
			this.speakerTimeDetectorService.localMicTimeData$,
			"teacher"
		);
	}

	@HostListener("document:fullscreenchange", ["$event"])
	@HostListener("document:webkitfullscreenchange", ["$event"])
	onFullScreenChange(event) {
		const d = document as any;
		const isFullScreen =
			d.fullscreenElement ||
			d.webkitFullscreenElement ||
			d.mozFullScreenElement ||
			d.msFullscreenElement;

		// @ts-ignore
		if (isFullScreen) {
			this.isFullscreen = true;
		} else {
			this.isFullscreen = false;
		}
	}

	@HostListener("window:resize", ["$event"])
	onResize(event) {
		placeNameOnVideos(this.isOldVariant);
		if (this.isBrowserSafari) {
			this.changeOrientation();
		}
	}

	@HostListener("window:popstate", ["$event"])
	onPopState(event) {
		this.doFinishCall(false, false).then();
	}

	private changeOrientation(): void {
		this.getOrientation();
		if (this.isDebug) {
			alert("window:orientationchange isPortrait=" + this.isPortrait);
		}
		this.changeDetector.markForCheck();
		this.rearrangeVideos();
		this.renderingComponent();
	}

	// todo: remove / refactor this mess - joe
	@HostListener("window:orientationchange", ["$event"])
	onOrientationChange(event) {
		this.updatePanelSplit = false;
		this.getOrientation();
		const isLandscape =
			this.platformDetectorService.isOrientationLandscape();
		if (this.isMobile) {
			if (
				(!this.isBrowserSafari && isLandscape) ||
				(this.isBrowserSafari && this.isPortrait)
			) {
				this.mobilePortraitViewIsSplit = this.isSplitView;
				if (!this.isSplitView) {
					this.doToggleSplitView(true);
				}
			} else if (!this.mobilePortraitViewIsSplit) {
				this.doToggleSplitView(false);
			}
		}
		if (this.isBrowserSafari || this.isTablet) {
			this.changeOrientation();
		}
		this.renderingComponent();
		setTimeout(() => {
			if (!this.isMobile) {
				this.checkForceSplitView();
			}
			this.updatePanelSplit = true;
		}, 300);
	}

	@HostListener("window:unload", ["$event"])
	unloadHandler(event) {
		this.authenticationService.updateInTheRoomStatus(false);
		this.onDestroy();
	}

	private checkForceSplitView() {
		if (this.innerHeight <= 480) {
			this.doToggleSplitView(true);
		}
	}
	async ngOnInit(): Promise<void> {
		if (environment.ENV === "master" || environment.ENV === "staging") {
			const watchRTCKeys = {
				id: this.roomState.isTeacher ? "teacher" : "student",
				type: "aggregate",
				name: this.roomState.isTeacher ? "teacher" : "student",
				label: this.roomState.isTeacher ? "Teacher" : "Student"
			};
			watchRTC.setConfig({
				rtcApiKey: environment.watchRTC,
				rtcRoomId: this.callStorage.roomId,
				rtcPeerId: this.usersService.selfUser.id
			});
			watchRTC.connect();
			watchRTC.addKeys(watchRTCKeys);
		}
		const isApprove = this.authenticationService.isApprover();
		const canJoin = this.authenticationService.canJoin();
		this.roomService.roomType$.subscribe((type) => {
			{
				this.roomType = type;
			}
		});

		if (canJoin && isApprove) {
			this.eventSocketService.notify(this.callStorage.roomId, {
				roomFull: false
			});
		}

		// const isUnlimitedCallTime = await this.accessControlService.hasAccess(Resources.UNLIMITED_CALL_TIME);

		this.mediaDevicesService.audioOutputDeviceChange$.subscribe(
			(audioDeviceId: string) => {
				this.outputDeviceId = audioDeviceId;
			}
		);

		this.roomLink = location.host + location.pathname;
		this.chatService.getDisplayChat$().subscribe((displayValue) => {
			this.displayChat = displayValue;
		});
		this.callCloud.getDisplayCloud$().subscribe((displayValue) => {
			this.displayCloud = displayValue;
		});
		this.chatService.getUnreadMessages$().subscribe((hasUnreadMessages) => {
			this.hasUnreadMessages = hasUnreadMessages && !this.displayChat;
		});
		this.notesService
			.getDisplayNotes$()
			.subscribe((displayValue) => (this.displayNotes = displayValue));
		if (!customElements.get("user-call")) {
			const userElement = createCustomElement(UserComponent, {
				injector: this.injectorLocal
			});
			customElements.define("user-call", userElement);
		}
		this.roomState.isLoading$.subscribe((state) => {
			this.isLoading = state;
		});

		this.callStorage.mainVideo$
			.pipe(takeUntil(this.destroyed))
			.subscribe((mainVideo) => {
				this.selfieVideoDisplayed =
					mainVideo.currentVideo === SELF_VIDEO;
				this.rearrangeVideos();
			});
		this.userName = sessionStorage.getItem(USER_KEY);

		// On video change orientation fix placement of user name
		this.videoService.onResize$
			.pipe(takeUntil(this.destroyed))
			.subscribe((video) => {
				placeNameOnVideos(this.isOldVariant);
			});
		this.isSchool = !!getSubDomain();
		this.profileService.get().subscribe((profile: Profile) => {
			this.userProfile = profile;
			this.tariff = profile?.subscription?.type;
			this.isBotOrProTeacher =
				BotService.isBot ||
				(this.roomState.isTeacher &&
					(TariffUtil.isTariffPro(this.tariff) ||
						this.tariff === Tariff.INSTITUTIONS)) ||
				["room-watchrtc", "room-60"].includes(
					this.callStorage.roomId
				) ||
				this.isSchool;
			this.profileId = profile?.userId;
			this.subrole = profile?.subrole;
			this.betaTesting = profile?.betaTesting;
			if (this.subrole === SubRole.TEACHER_MATCHED) {
				this.getBookingInfo(this.profileId);
			}
			this.roomState.periodWarning$
				.pipe(takeUntil(this.destroyed))
				.subscribe((value: number) =>
					this.startReleaseWarningTimeout(value)
				);
			this.roomState.periodRelease$
				.pipe(takeUntil(this.destroyed))
				.subscribe(() => this.doFinishCall(true, true));
		});

		this.authenticationService.isLoggedIn$
			.pipe(takeUntil(this.destroyed))
			.subscribe(async (loggedIn) => {
				if (!this.wasLoggedIn && loggedIn) {
					this.wasLoggedIn = true;
				} else if (this.wasLoggedIn && !loggedIn) {
					this.doFinishCall(false, false);
					await this.routerLocal.navigateByUrl("/login");
				}
			});

		// Chat Room validation
		await this.isChatRoomCreated();

		const isReflection = LocalStorageUtil.getItem(
			MEDIA_VIDEO_IS_REFLECTION
		);
		this.isReflection =
			isReflection !== null ? isReflection === "true" : true;
		this.videoService.isReflection$.next(this.isReflection);
		const isReflection$ = this.videoService.isReflection$.asObservable();
		isReflection$.subscribe(
			(reflection) => (this.isReflection = reflection)
		);
		this.readMediaPropertiesFromStorage();

		this.audioContext = this.audioContextService.getAudioContext();
		await this.initSoundPlayer(this.audioContext);
		this.isSign = false;
		const data = this.roomState.isTeacher ? { resetRecording: true } : {};
		this.eventSocketService.notify(this.callStorage.roomId, data);
		ensureGlobalObject("APP.conference");

		// this.recordingService.watchRecordingState(this.roomName);
		// let user = this.userId?this.userId:this.usersService.selfUser.id;
		if (this.isStudentTrial()) {
			this.saveTeacherNameToStorage();
		}

		this.setPosition(this.tunerPosition.x, this.tunerPosition.y);

		this.isTrial$ = this.lessonService.isTrial(this.bookingType);


		return Promise.resolve();
	}

	ngDoCheck(): void {
		this.currentVideoWidth = this.getCurrentWidth;
		const videoElement: any = document.querySelector(
			"div.main-panel.ng-star-inserted.is-cam-on > app-view-media > video"
		);
		if (videoElement && this.prevVideoWidth !== videoElement.offsetWidth) {
			this.prevVideoWidth = videoElement?.offsetWidth;
		}
	}

	async ngOnDestroy(): Promise<void> {
		const settingsPopupCancel = document.querySelector(
			".st-icon-close"
		) as HTMLElement;
		if (settingsPopupCancel) {
			settingsPopupCancel.click();
		}
		this.isDestroy = true;
		this.authenticationService.updateInTheRoomStatus(false);
		sessionStorage.isLive = false;
		sessionStorage.hidePassword = false;
		this.destroySoundPlayer();
		await this.emojiService.closeAudioContext();
		this.clearNextLessonTimer();
		this.onDestroy();
	}
	public renderingComponent(): void {
		if (this.isDestroy) {
			return;
		}
		this.changeDetector.detectChanges();
		if (this.isBrowserSafari) {
			setTimeout(() => {
				this.changeDetector.detectChanges();
			}, 50);
		}
	}

	public saveTeacherNameToStorage(): void {
		const teacherName = this.getConnectedUsers().find(
			(u) => u.isTeacher
		)?.name;
		if (teacherName) {
			sessionStorage.setItem("teacherName", teacherName);
		}
	}

	public hasSameOrder(arr1, arr2) {
		if (arr1.length !== arr2.length) {
			return false;
		}

		for (let i = 0; i < arr1.length; i++) {
			if (arr1[i] !== arr2[i]) {
				return false;
			}
		}

		return true;
	}

	public userOnPanel(): UserVO {
		return this.usersService.userOnPanel;
	}
	public getConnectedUsers(): UserVO[] {
		return this.usersService.getConnectedUsers();
	}

	public doShowUserOnPanel(userVO: UserVO): void {
		this.usersService.setUserOnPanel(userVO);
		this.renderingComponent();
	}

	doToggleMaximizeSecondStream(user: {
		user: UserVO;
		secondStream: boolean;
	}) {
		this.subscribesLocal.push(
			user.user.secondStreamActive$.subscribe((value) => {
				if (!value) {
					this.maximizeSecondCam = false;
				}
			})
		);
		this.maximizeSecondCam = true;
		this.doToggleMaximize(user.user, true);
	}

	doToggleMaximize(userVO: UserVO, secondStream?: boolean): void {
		secondStream
			? (this.maximizeSecondCam = true)
			: (this.maximizeSecondCam = false);
		const isLandscape =
			this.platformDetectorService.isOrientationLandscape();
		if (this.userOnPanel() === userVO && !this.isSplitView) {
			this.doToggleSplitView(true);
		} else if (!(this.isMobile && isLandscape)) {
			this.usersService.setUserOnPanel(userVO);
			this.doToggleSplitView(false);
			this.renderingComponent();
		} else if (!this.isSplitView) {
			this.usersService.setUserOnPanel(userVO);
			this.renderingComponent();
		}
	}

	doToggleTimer(): void {
		this.showTimer = !this.showTimer;
	}

	toggleFullScreen(value: boolean): void {
		if (value) {
			this.isFullscreen = true;
			if (
				!document.fullscreenElement &&
				!(document as any).webkitFullscreenElement
			) {
				if (document.documentElement.requestFullscreen) {
					document.documentElement.requestFullscreen();
				} else if (
					(document.documentElement as any).webkitRequestFullscreen
				) {
					// safari hack
					(document.documentElement as any).webkitRequestFullscreen();
				}
			}
		} else {
			if (document.fullscreenElement) {
				document.exitFullscreen();
				this.isFullscreen = false;
			} else if ((document as any).webkitFullscreenElement) {
				(document as any).webkitExitFullscreen();
			}
			this.isFullscreen = false;
		}
	}

	public doToggleFullscreen(user: UserVO): void {
		if (this.userOnPanel() === user && this.isFullscreen) {
			this.toggleFullScreen(false);
		} else {
			this.usersService.setUserOnPanel(user);
			this.doToggleSplitView(false);
			this.toggleFullScreen(true);
		}
	}

	public isHideSelf(user: UserVO): boolean {
		return user === this.usersService.selfUser;
	}

	public isIconRoundLetter(userName: string): boolean {
		return userName !== "";
	}
	public getInitial(userName: string): string {
		return (userName || " ").charAt(0).toUpperCase();
	}
	public getOrientation(): void {
		this.isPortrait = this.platformDetectorService.isOrientationPortrait();
		this.isLandscape = !this.isPortrait;
	}
	public selfUser(): UserVO {
		return this.usersService.selfUser;
	}
	public changeIsPortrait(): void {
		if (this.isBrowserSafari) {
			this.renderingComponent();
		}
	}
	// todo remove dispatch to some service
	public addObservers() {
		if (this.subscribesLocal && this.subscribesLocal.length > 0) {
			this.unsubscribe();
		}
		// this.subscribesLocal.push(this.roomState.isHandOn$.subscribe((state) => {
		//   this.audioInterruptPlay();??
		//   this.eventSocketService.turn('risehand', state);
		// }));
		this.subscribesLocal.push(
			this.roomState.displayMode$.subscribe((state) => {
				if (state === DisplayStatus.active) {
					this.isDisplayStatusActive = true;
					this.usersService.selfUser.screenShareStream =
						this.streamViewModel.screenShareStream;
					this.eventSocketService.turn("screenshare", true);
				} else if (
					state === DisplayStatus.inactive &&
					this.isDisplayStatusActive
				) {
					this.isDisplayStatusActive = false;
					this.usersService.selfUser.screenShareStream = null;
					this.usersService.selfUser.mediaStream =
						EasyRtcService.getLocalStream();
					this.eventSocketService.turn("screenshare", false);
				}
				this.changeDetector.detectChanges();
			})
		);
		this.roomState.isRecording$
			.pipe(takeUntil(this.destroyed))
			.subscribe((isRecording: boolean) => {
				if (isRecording) {
					this.recordingWait();
				}
				this.eventSocketService.turn("recording", isRecording);
			});
		this.callStorage.usersStorage.onUsersUpdate$
			.pipe(takeUntil(this.destroyed))
			.subscribe(() => {
				this.usersCount = this.callStorage.usersStorage.getUsersCount();
				setTimeout(() => {
					this.rearrangeVideos();
				});
				if (this.isStudentTrial()) {
					this.saveTeacherNameToStorage();
				}
			});
	}
	doToggleHandOn(value: boolean): void {
		this.isHandOn = value;
		// if (this.isHandOn) {
		//   this.audioInterruptPlay();
		// }
		this.eventSocketService.turn("risehand", value);
	}
	// remove recording section from here
	protected recordingError(): void {
		this.popupService.openSnackBar("popUp.recordError");
	}

	protected recordingWait(): void {
		this.popupService.openSnackBar("popUp.recordWait");
	}

	protected recordingFail(): void {
		this.popupService.openSnackBar("popUp.recordFail");
	}

	protected recordingStarted(): void {
		this.popupService.openSnackBar("popUp.recordStarted");
	}

	protected recordingStopped(): void {
		this.popupService.openSnackBar("popUp.recordStopped");
	}

	private unsubscribe() {
		this.subscribesLocal.forEach((subscribe) => {
			subscribe.unsubscribe();
		});
		this.subscribesLocal = [];
	}
	protected turnHandler(data): void {
		this.roomState.isRecording = data.recording;
		// Moved to \app\services\ws\room-data-parser.service.ts parse().
		// sessionStorage.reconnectTurn = true;
		// if (this.oldRisehandStatuses.length === 0) {
		//   this.oldRisehandStatuses = data.users.map(user => user.options.risehand);
		// } else {
		//   // That's a workaround because list of UserVO's is constantly recreated on receiving changes from server
		//   for (const index in this.oldRisehandStatuses) {
		//     if (this.oldRisehandStatuses[index] !== data.users[index].options.risehand) {
		//       this.oldRisehandStatuses[index] = data.users[index].options.risehand;
		//       this.audioInterruptPlay();
		//     }
		//   }
		// }

		// Check, maybe some student want to join
		this.fillJoinList(data);
	}

	onDestroy(): void {
		// window.removeEventListener('blur', this.onBlurMobile);
		this.destroyed.next();
		this.destroyed.unsubscribe();
		this.unsubscribe();
		this.clearReleaseWarningTimeout();
		this.streamViewModel.disconnect();
		if (environment.ENV !== "local") {
			watchRTC.disconnect();
		}
	}

	async doFinishCall(
		redirect /*= true*/,
		isJoinExpires /*= false*/
	): Promise<void> {
		if (!!sessionStorage.isLive) {
			this.isCallEnded = true;
			this.callRoomService.handleFinishCall();
			const waitingTime = Math.floor(Math.random() * 800);
			await this.mediaDevicesService.sleep(waitingTime);
			const { roomId, userName, agent, device, camera, mic } =
				this.callRoomService.getSocketGoneParams();

			if (redirect && this.isStudentTrial()) {
				StorageUtil.writeLessonEndTimeToLocalStorage(
					roomId,
					sessionStorage.getItem("teacherName")
				);
			}

			this.eventSocketService.gone(
				roomId,
				userName,
				agent,
				device,
				camera,
				mic
			);
			if (this.currentLesson) {
				this.onSaveSpeakerTimes();
			}
			this.clearReleaseWarningTimeout();
			const mediaStream = this.usersService.selfUser.mediaStream;
			// console.log(mediaStream);
			// console.log(this.usersService.selfUser.mediaStream);
			this.usersService.selfUser.mediaStream = null;
			if (!!mediaStream) {
				await this.easyRtcService.stopMediaStreamTrack(
					mediaStream.getTracks()
				);
			}
			if (this.roomState.isScreenShare !== DisplayStatus.inactive) {
				this.roomState.isScreenShare = DisplayStatus.inactive;
			}
			if (redirect) {
				this.audioContextService.closeAudioContext();
				if (this.isStudentTrial()) {
					this.mixpanelService.finishTrialStudent(this.userId);
					this.routerLocal
						.navigate(["checkout"], {
							queryParams: { externalLink: false }
						})
						.then(() => {
							this.roomState.hasFinishedCall$.next(true);
						});
				} else {
					const redirectTo =
						this.callRoomService.getRedirectPathAfterEndCall(
							this.isNotSubDomainAndFree,
							isJoinExpires,
							this.tariff,
							this.roomType,
							this.subrole
						);
					const isTariffPro = TariffUtil.isTariffPro(this.tariff);

					this.routerLocal
						.navigate(redirectTo, {
							state: {
								isTariffPro,
								isScheduledLesson:
									this.roomType === RoomType.LESSON,
								subrole: this.subrole,
								userId: this.userId
							}
						})
						.then(() => {
							this.roomState.hasFinishedCall$.next(true);
						});
				}
			}
		}

		// Will be done on BE after 5-10 minutes
		// if (this.authenticationService.isLoggedIn$.getValue()) {
		//   this.callStorage.usersStorage.users.forEach((user) => {
		//     this.webSocketService.dispatch(AFFECT, {option: KICK, target: user.id});
		//   });
		// }
	}
	createNewVideo = (rtcId: string) =>
		this.callRoomService.createNewVideo(rtcId, this.isOldVariant);

	getVideoById(id: string) {
		return getVideoById(id, this.isOldVariant);
	}

	getSelfVideo(): HTMLVideoElement {
		return getSelfVideo(this.isOldVariant);
	}

	getMainVideo(): HTMLVideoElement {
		return getMainVideo(this.isOldVariant);
	}

	removeChildById(id: string) {
		removeChildById(id);
	}
	onSelfVideoClicked() {
		if (!this.isSplitViewEnabled) {
			this.callStorage.updateMainVideo(SELF_VIDEO);
		}
	}
	onMainVideoClicked() {} // legacy

	init = (adress: string, name: string) => {
		this.streamViewModel
			.connect(adress, name, this.initStreamViewModel())
			.then(async (connect) => {
				const hasConnectedUser =
					this.usersService.getConnectedUsers().length > 0;
				if (BotService.isBot && hasConnectedUser && connect) {
					await new Promise((r) => setTimeout(r, 1000));
					ensureGlobalObject("APP.conference._room").isJoined = () =>
						true;
					await this.recordingService
						.confirm(this.callStorage.title)
						.toPromise();
				}
				this.changeDetector.markForCheck();
			});
	};

	initStreamViewModel(): Room {
		return null;
	} // need to override

	protected joinHandler(data): void {
		this.callRoomService.handleJoin(data);
		// If on iOS user switch camera off and then switch it on, his selfie screen remains black
		// if (this.isMobile) {
		//   window.addEventListener('blur', this.onBlurMobile);
		// }
		this.fillJoinList(data);
	}

	protected goneHandler(data): void {
		this.fillJoinList(data);
	}

	private fillJoinList(data): void {
		const isApprover = this.authenticationService.isApprover();
		if (isApprover) {
			const newRequestsCount = data.users.filter(
				(user) =>
					!user.connected &&
					user.options.demand &&
					!user.options.recordingBot
			).length;
			if (this.joinList && this.joinList.length < newRequestsCount) {
				// play sound only if new request are added
				this.callRoomService.audioKnock.play().catch((err) => {
					console.error(err);
				});
			}
			this.joinList = [];
			data.users.forEach((user) => {
				if (
					!user.connected &&
					user.options.demand &&
					!user.options.recordingBot
				) {
					this.requestJoin(user.name, user.id);
				} else if (
					!user.connected &&
					user.options.demand &&
					user.options.recordingBot
				) {
					this.approveJoin(user.id);
				}
			});
		}
		if (
			data.users.filter((u) => u.connected).length === 1 &&
			!this.playerStateService.playerUser
		) {
			// only you in room
			this.doShowUserOnPanel(this.usersService.selfUser);
		}
	}

	private requestJoin(name: string, userId: string): void {
		this.joinList.push({ userId, name });
	}

	public approveJoin(userId: string): void {
		return this.handleJoin(userId, true);
		// const params = this.callRoomService.getJoinParams();
		// try {
		// 	this.webSocketService.dispatch(JOIN, params);
		// } catch (error) {
		// 	logger.log("[catch] Join error: ", error);
		// }
	}

	public rejectJoin(userId: string): void {
		this.handleJoin(userId, false);
	}

	public addTeacherRequest(
		name: string,
		unmute: boolean,
		unhide: boolean
	): void {
		if (unmute && !this.isTeacherRequestUnmute) {
			this.isTeacherRequestUnmute = true;
			this.teacherRequestList.push({ name, unmute, unhide });
		}
		if (unhide && !this.isTeacherRequestUnhide) {
			this.isTeacherRequestUnhide = true;
			this.teacherRequestList.push({ name, unmute, unhide });
		}
	}

	public rejectTeacher(teacherRequest: TeacherRequest): void {
		if (!!teacherRequest) {
			const idx = this.teacherRequestList.indexOf(teacherRequest);
			if (idx > -1) {
				if (teacherRequest.unmute) {
					this.isTeacherRequestUnmute = false;
				}
				if (teacherRequest.unhide) {
					this.isTeacherRequestUnhide = false;
				}
				this.teacherRequestList.splice(idx, 1);
			}
		}
	}

	public approveTeacher(teacherRequest: TeacherRequest): void {
		if (!!teacherRequest) {
			if (this.teacherRequestList.includes(teacherRequest)) {
				if (teacherRequest.unmute) {
					this.doToggleMicro(true, true);
				} else if (teacherRequest.unhide) {
					this.doToggleCamera(true, true);
				}
			}
			this.rejectTeacher(teacherRequest);
		}
	}

	private handleJoin(userId: string, result: boolean): void {
		this.webSocketService.dispatch(DEMAND, { userId, join: result });
		this.closeJoinPopup(userId);
	}

	closeJoinPopup(userId: string): void {
		this.joinList = this.joinList.filter(
			(joinItem) => joinItem.userId !== userId
		);
	}

	doToggleChat(): void {
		this.displayChat = !this.displayChat;
		this.hasUnreadMessages = this.hasUnreadMessages && !this.displayChat;
		this.chatService.getDisplayChat$().next(this.displayChat);
	}
	doToggleNotes(): void {
		logger.log("doToggleNotes");
		this.displayNotes = !this.displayNotes;
	}


	// toggles camera

	doToggleCamera(isCamera: boolean, isForce: boolean): void {
		if (this.usersService.selfUser.isCamera !== isCamera || isForce) {
			this.usersService.selfUser.isCamera = isCamera;
			this.roomState.isCamOn = isCamera;
			StorageUtil.writeMediaPrmsToSessionStorage({ isCam: isCamera });
			this.streamViewModel.enableCamera(isCamera);
			this.eventSocketService.turn("camera", isCamera);
		}
	}

	async doToggleSwitchCam(isSwitchCam: boolean): Promise<void> {
		this.switchCam = isSwitchCam;
		const facingMode = {
			facingMode: { exact: !this.switchCam ? "user" : "environment" }
		};
		this.shouldNotMirrored =
			facingMode.facingMode.exact === "environment" &&
			this.platformDetectorService.isBrowserSafari();
		await this.registerSettingsChange(facingMode);
	}

	async registerSettingsChange(facingMode: {
		facingMode: { exact: string };
	}): Promise<void> {
		this.latencyTurnCamera = true;
		const mediaProperties =
			MediaProperiesUtil.readMediaProperiesFromStorage();
		const mediaConstraints = { ...mediaProperties, facingMode };
		const constraints: any =
			MediaProperiesUtil.createConstraints(mediaConstraints);

		let mediaStream = EasyRtcService.getLocalStream();
		if (!mediaStream) {
			const mediaIds: any = EasyRtcService.getLocalMediaIds();
			mediaStream = EasyRtcService.getLocalStream(mediaIds[0]);
		}
		if (!!mediaStream) {
			await this.easyRtcService.stopMediaStreamTrack(
				mediaStream.getTracks()
			);
			this.settingsStorage.currentStream = null;
			this.usersService.selfUser.mediaStream = null;
		}
		constraints.video = facingMode;
		if (this.platformDetectorService.isBrowserSafari()) {
			await new Promise((r) => setTimeout(r, 700));
		}
		await this.rtcStreamViewModel.changeSelectedStream(constraints);
		await new Promise((r) =>
			setTimeout(() => (this.latencyTurnCamera = false), 1000)
		);
	}


	// toggles Micro

	doToggleMicro(isMicrophone: boolean, isForce: boolean): void {
		if (this.usersService.selfUser.isMicro !== isMicrophone || isForce) {
			this.roomState.isMicroOn = isMicrophone;
			this.usersService.selfUser.isMicro = isMicrophone;
			StorageUtil.writeMediaPrmsToSessionStorage({
				isMicro: isMicrophone
			});
			this.streamViewModel.enableMicrophone(isMicrophone);
			this.eventSocketService.turn("microphone", isMicrophone);
			if (this.roomState.isTeacher) {
				if (!isMicrophone) {
					this.speakerTimeDetectorService.audioContext?.suspend();
				} else {
					this.speakerTimeDetectorService.audioContext?.resume();
				}
			}
		}
	}

	doToggleInvitation(): void {
		this.roomPassword = sessionStorage.roomPassword;
		this.displayInvitation = !this.displayInvitation;
	}

	async doToggleSettings(switchToTab: number = null): Promise<void> {
		this.isSettingsDialogOpen = true;
		const dialogRef = await this.popupService
			.settings("MediaStream", {
				switchToTab
			})
			.afterClosed()
			.toPromise();
		if (dialogRef) {
			this.isSettingsDialogOpen = false;
		}
	}

	doToggleEmoji(value: boolean): void {
		this.isEmoji = value;
		this.renderingComponent();
	}

	doToggleCloud(): void {
		this.displayCloud = !this.displayCloud;
		this.callCloud.getDisplayCloud$().next(this.displayCloud);
		this.renderingComponent();
	}

	doToggleMetronome(value: boolean): void {
		this.isMetronome = value;
		this.renderingComponent();
	}

	doToggleTuner(value: boolean): void {
		this.isTuner = value;
		this.renderingComponent();
	}

	onEmojiSelected(emoji: number): void {
		this.eventSocketService.notify(this.callStorage.roomId, {
			emoji: emoji,
			hasEmoji: true,
			userId: this.usersService.selfUser.id
		});
	}

	doToggleShare(isToggleShare: boolean) {
		this.toggleShare = isToggleShare;
	}

	onToggleRecord(isRecord: boolean) {
		if (
			!this.isBotOrProTeacher &&
			this.subrole !== 3 &&
			!isRecord &&
			this.roomType != "external"
		) {
			this.popupService.openRecordWarning(
				"recordings.free-users-recoding-warning",
				"recordings.free-users-recoding-warning-title",
				[
					{
						type: ButtonType.CANCEL,
						text: "recordings.btn-dont-upgrade"
					},
					{
						type: ButtonType.OK,
						text: "recordings.btn-upgrade",
						callBack: () => {
							const url = this.routerLocal.serializeUrl(
								this.routerLocal.createUrlTree([
									"/platform/subscription"
								])
							);
							window.open(url, "_blank");
						}
					}
				]
			);
			return;
		}
		this.isRecordingTurnOn = isRecord;
		if (isRecord) {
			this.startRecording();
		} else {
			this.stopRecording();
		}
	}

	private startRecording(): void {
		this.showRecordingError = false;
		this.recordingService
			.start(this.roomName, window.location.origin)
			.pipe(
				catchError((err) => {
					console.log("Problem starting recording.", err);

					this.showRecordingError = true;
					setTimeout(() => {
						this.showRecordingError = false;
					}, 3000);

					return throwError(err);
				}),
				mergeMap((start) => {
					console.log("[rec] start: ", start);
					return this.recordingService
						.getListByRoomTitle(this.roomName)
						.pipe(
							catchError((err) => {
								console.log(
									"Problem getting recording list",
									err
								);
								return throwError(err);
							})
						);
				})
			)
			.subscribe(({ list }) =>
				this.recordingService.recordingList$.next(list)
			);
	}

	private stopRecording(): void {
		const recordingState = this.recordingService.recordingState$.value;
		this.recordingService
			.stop(this.roomName)
			.pipe(
				catchError((err) => {
					console.log("Problem stopping recording.", err);
					return throwError(err);
				}),
				mergeMap((stop) => {
					console.log("[rec] stop: ", stop);
					if (recordingState === "recording" && stop) {
						return this.recordingService
							.getListByRoomTitle(this.roomName)
							.pipe(
								catchError((err) => {
									console.log(
										"Problem getting recording list",
										err
									);
									return throwError(err);
								})
							);
					}
					return of(stop);
				})
			)
			.subscribe((result) => {
				if (typeof result === "object") {
					const recordLink = this.findNewRecordingLink(result.list);

					if (recordLink) {
						this.chatService.sendSocketMessage(recordLink);
					}
				}
			});
	}

	findNewRecordingLink(recordings: RecordingListItem[]): string {
		const oldRecordings = this.recordingService.recordingList$.value;
		if (recordings && recordings.length === 0) {
			return "";
		}
		if (recordings && recordings.length > 0 && oldRecordings.length > 0) {
			const recIds = oldRecordings.map((rec) => rec.id);
			const newRecording = recordings.find(
				(rec) => !recIds.includes(rec.id)
			);
			if (newRecording) {
				return newRecording.url;
			} else {
				return "";
			}
		}
		if (recordings && recordings.length > 0 && oldRecordings.length === 0) {
			const newRecording = recordings[0];
			if (newRecording) {
				return newRecording.url;
			} else {
				return "";
			}
		}
		return "";
	}

	getUserEmoji(id) {
		return this.emojiService.userEmoji(id);
	}

	doToggleScreenShare(value: boolean): void {
		this.isScreenShare = value;
		switch (this.roomState.isScreenShare) {
			case DisplayStatus.active: {
				this.roomState.displayMode = DisplayStatus.loadingToInactive;
				break;
			}
			case DisplayStatus.inactive: {
				this.roomState.displayMode = DisplayStatus.loadingToActive;
				break;
			}
		}
	}

	doToggleStopYouTube() {
		this.doYoutubeVideoId(null);
	}

	doYoutubeVideoId(videoId: string) {
		if (!videoId) {
			this.eventSocketService.notify(this.callStorage.roomId, {
				youtube: {
					videoId,
					event: "stop"
				}
			});
			this.isYouTube = false;
			this.playerStateService.youtubeActive = false;
			this.playerStateService.playerUser = null;
			const updatedUsers = this.usersService.userList.filter(
				(user) => !user?.hasYoutube
			);
			updatedUsers.forEach((user) => (user.youTubeVideoId = null));
			this.usersService.updateUserList(updatedUsers);
			this.doToggleMicro(true, false);
			localStorage.removeItem("videoId");
			return;
		}
		this.eventSocketService.notify(this.callStorage.roomId, {
			youtube: {
				videoId,
				event: "start"
			}
		});
		this.doToggleMicro(false, false);
		const user = new UserVO();
		user.isCamera = false;
		user.isMicro = false;
		user.youTubeVideoId = videoId;
		user.hasYoutube = true;
		user.id = videoId;
		this.playerStateService.playerUser = user;
		const newUsers = [user, ...this.usersService.userList];
		newUsers.forEach((user) => (user.youTubeVideoId = videoId));
		this.usersService.updateUserList(newUsers);
		this.usersService.setUserOnPanel(user);
		this.isSplitViewEnabled = false;
		this.isSplitView = false;
		this.isYouTube = true;
		localStorage.setItem("videoId", videoId);
	}

	doToggleSplitView(value: boolean): void {
		if (!!sessionStorage.isLive) {
			this.isSplitViewEnabled = value;
			this.isSplitView = value;
			if (this.isYouTube) {
				const isUserOnPanel = this.usersService.isUserOnPanel(
					this.playerStateService.playerUser
				);
				const players = this.playerStateService.playerEvent;
				if (!this.isSplitView && isUserOnPanel) {
					if (players[1].getVolume() > 0) {
						players[0].setVolume(players[1].getVolume());
						players[1].setVolume(0);
					}
				} else {
					if (players[0].getVolume() > 0) {
						players[1].setVolume(players[0].getVolume());
						players[0].setVolume(0);
					}
				}
			}
			this.rearrangeVideos();
		}
	}

	rearrangeVideos(): void {
		if (!this.isOldVariant) {
			return null;
		}
	}

	public metronomeClose(): void {
		this.isMetronome = false;
	}

	public tunerClose(): void {
		this.isTuner = false;
	}
	public emojiClose(): void {
		this.isEmoji = false;
	}

	public releaseWarningClose(): void {
		this.isShowReleaseWarning = false;
		this.clearReleaseWarningTimeout();
	}

	private startReleaseWarningTimeout(value: number): void {
		this.clearReleaseWarningTimeout();
		if (value >= 0) {
			this.timeReleaseWarning = value;
			this.isShowReleaseWarning = true;
			this.periodReleaseView();
			this.releaseWarningTimeout = setInterval(() => {
				if (this.timeReleaseWarning >= 0) {
					this.timeReleaseWarning -= 1000;
					this.periodReleaseView();
				} else {
					this.clearReleaseWarningTimeout();
				}
			}, 1000);
		}
	}

	private periodReleaseView(): void {
		if (!!this.timeReleaseWarning) {
			this.periodWarning = FormatTimeUtil.toString(
				this.timeReleaseWarning,
				true,
				false,
				false
			);
			this.changeDetector.detectChanges();
		}
	}

	private clearReleaseWarningTimeout(): void {
		if (!!this.releaseWarningTimeout) {
			clearTimeout(this.releaseWarningTimeout);
			this.releaseWarningTimeout = null;
		}
		this.timeReleaseWarning = null;
		this.isShowReleaseWarning = false;
	}

	private async initSoundPlayer(audioContext: AudioContext): Promise<void> {
		if (!!audioContext) {
			this.soundPlayerHandOn = new SoundPlayer(this.audioContext);
			const firstAudioBuffer =
				await SoundPlayerUtil.fetchAndCreateBufferSource(
					this.audioContext,
					"/assets/BellBouncer_short.mp3"
				);
			await this.soundPlayerHandOn.setAudioBuffer(firstAudioBuffer);
		}
	}

	private destroySoundPlayer(): void {
		this.soundPlayerHandOn.OnDestroy();
	}

	protected audioInterruptPlay(): void {
		logger.log("audioInterrupt");
		this.soundPlayerHandOn.play();
	}

	private readMediaPropertiesFromStorage(): void {
		this.outputDeviceId = LocalStorageUtil.getItem(
			MEDIA_AUDIO_OUTPUT_DEVICE
		);
	}

	updateNotJoinedList(event: NotifyPanel[]): void {
		this.notJoinedList = event;
	}

	public isSingleUser(): boolean {
		return this.usersService.getConnectedUsers().length === 1;
	}

	public isTeacher() {
		return this.roomState.isTeacher;
	}

	doTogglePiano(value: boolean): void {
		// console.log("value" + value);
		this.isPiano = value;
		this.renderingComponent();
	}

	get isPortraitTablet(): boolean {
		return (
			this.platformDetectorService.isTablet &&
			this.platformDetectorService.isOrientationPortrait()
		);
	}

	get isLandscapeIpad(): boolean {
		return (
			this.platformDetectorService.isTablet() &&
			this.platformDetectorService.isOrientationLandscape() &&
			this.platformDetectorService.isBrowserSafari()
		);
	}

	get isIpadSafari(): boolean {
		return (
			this.platformDetectorService.isTablet() &&
			this.platformDetectorService.isIPad()
		);
	}

	get theme() {
		return BG_THEMES[this.bgColor] || BG_THEMES[DEFAULT_BG_COLOR];
	}

	get isMainPanelMirrored(): boolean {
		const userOnPanel = this.userOnPanel();
		const status = this.mirrorService.mirroring[userOnPanel.id];
		if (!userOnPanel.screenShareStream && !this.shouldNotMirrored) {
			return status !== undefined
				? status
				: userOnPanel.self && this.isReflection;
		}

		return false;
	}

	get teacherId() {
		return this.isTeacher()
			? this.profileId
			: sessionStorage.getItem("teacherId");
	}

	get studentId() {
		return this.isTeacher()
			? sessionStorage.getItem("studentId")
			: this.profileId;
	}

	private setisHiddenNotes(): void {
		this.isHiddenNotes = !(
			this.isScheduledLesson &&
			["SINGLE", "REGULAR", "REPEAT"].includes(this.bookingType)
		);
	}

	private async getBookingInfo(userId): Promise<void> {
		await this.lessonService
			.getNextBookings(userId)
			.subscribe((nextLessons) => {
				if (nextLessons) {
					const currentLesson = nextLessons["list"].find((lesson) => {
						return lesson.targetTeacher.includes(
							this.callStorage.roomId
						);
					});
					this.currentLesson = currentLesson;
					this.currentStudent = currentLesson?.student;
					const currentLessonIndex =
						nextLessons["list"].indexOf(currentLesson);
					this.nextLesson =
						nextLessons["list"][currentLessonIndex + 1] || null;

					if (this.nextLesson) {
						this.setNextLessonTimer();
					}
				}
			});
	}

	private setNextLessonTimer() {
		const nextLesson = this.nextLesson;
		if (nextLesson) {
			const nextLessonDate = dayjs(nextLesson.startTime);
			const now = dayjs();
			const diff = nextLessonDate.diff(now);
			this.nextLessonTimer = setTimeout(() => {
				this.showNextLessonAlert = true;
				this.clearNextLessonTimer();
			}, diff);
		}
	}

	private clearNextLessonTimer() {
		if (this.nextLessonTimer) {
			clearTimeout(this.nextLessonTimer);
			this.nextLessonTimer = null;
		}
	}

	get nextStudentName() {
		return this.nextLesson?.student?.name;
	}

	async goToNextLesson() {
		await this.doFinishCall(false, false);
		window.location.href = `${this.nextLesson?.targetTeacher}`;
	}

	public isStudentTrial() {
		const res =
			this.isScheduledLesson &&
			!["SINGLE", "REGULAR", "REPEAT"].includes(this.bookingType) &&
			!this.isTeacher() &&
			this.roomType !== RoomType.EXTERNAL &&
			this.roomType !== RoomType.PERSONAL;

		return res;
	}
	async isChatRoomCreated(): Promise<void> {
		try {
			const queryString = window.location.search;
			if (queryString) {
				const urlParams = new URLSearchParams(queryString);
				const roomKey = urlParams.get("roomKey");

				const roomData = await this.roomAuthService
					.getRoomInfo(roomKey)
					.toPromise();

				const [userId1, userId2] = this.roomState.isTeacher
					? [roomData.userId, this.studentId]
					: [roomData.userId, this.userId];

				const { count, list } = await this.newChatRoomInfo(
					userId1,
					userId2
				);
				this.isRoomIdCreated = count >= 1;
			}
		} catch (err) {
			console.error(err);
		}
	}

	newChatRoomInfo(teacherId: string, studentId: string): Promise<any> {
		return this.talkjsService
			.getChatRoomId(teacherId, studentId)
			.toPromise();
	}

	onTouchStart(event: TouchEvent): void {
		this.isDragging = true;

		const touch = event.touches[0];
		this.offset = {
			x: touch.clientX - this.tunerElement.nativeElement.offsetLeft,
			y: touch.clientY - this.tunerElement.nativeElement.offsetTop
		};

		event.preventDefault(); // Evita comportamientos predeterminados
	}

	onTouchMove(event: TouchEvent): void {
		if (this.isDragging) {
			const touch = event.touches[0];
			const newX = touch.clientX - this.offset.x;
			const newY = touch.clientY - this.offset.y;

			this.setPosition(newX, newY);
			this.tunerPosition = { x: newX, y: newY };
		}
	}

	onTouchEnd(): void {
		this.isDragging = false;
	}

	private setPosition(x: number, y: number): void {
		if (!this.tunerElement) return;

		const element = this.tunerElement.nativeElement;
		element.style.left = `${x}px`;
		element.style.top = `${y}px`;
	}

	onMouseUp(): void {
		this.isDragging = false;
	}

	onMouseDown(event: MouseEvent): void {
		if (!this.tunerElement) return;
		this.isDragging = true;
		this.offset = {
			x: event.clientX - this.tunerElement.nativeElement.offsetLeft,
			y: event.clientY - this.tunerElement.nativeElement.offsetTop
		};
		event.preventDefault();
	}

	onMouseMove(event: MouseEvent): void {
		if (this.isDragging && this.tunerElement) {
			const newX = event.clientX - this.offset.x;
			const newY = event.clientY - this.offset.y;
			this.setPosition(newX, newY);
			this.tunerPosition = { x: newX, y: newY };
		}
	}

	private subscribeToSpeakerTime(
		observable$: Observable<any>,
		userType: string
	): void {
		observable$.subscribe((speakerTimeInfo) => {
			const key = Object.keys(speakerTimeInfo);
			const timerInfo = speakerTimeInfo[key[0]];

			if (timerInfo) {
				const { timer } = timerInfo;

				if (userType === "student") {
					this.studentTimer = timer;
				} else if (userType === "teacher") {
					this.teacherTimer = timer;
				}

				// 300 seconds -> 5 minutes
				if (timer % 300 === 0) {
					this.storeSpeakersTimeSubject.next({
						userType,
						time: timer
					});
				}
			}
		});
	}

	private storeSpeakersTimeHandler(
		userType: string,
		time: number
	): Promise<void> {
		const { id: bookingId, startTime, endTime } = this.currentLesson;
		const userId =
			userType === "student"
				? this.currentLesson?.student.id
				: this.currentLesson?.teacher.id;
		return this.qualityRating
			.postSpeakerTime(
				bookingId,
				userId,
				userType,
				time,
				startTime,
				endTime
			)
			.toPromise()
			.then(() => {})
			.catch((err) => {
				console.error("Error:", err);
			});
	}

	onSaveSpeakerTimes(): void {
		this.storeSpeakersTimeHandler("student", this.studentTimer);
		this.storeSpeakersTimeHandler("teacher", this.teacherTimer);
	}
}
